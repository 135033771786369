import React from 'react'
import { markdownify } from '../../../../utils'
import { Li, Ul } from '../../../atoms/Lists'
import { ReactComponent as Check } from '../../../../images/check.svg'
import styles from './MilestoneDescription.module.css'

export default function MilestoneDescription({
  description,
  done
}: {
  description: string[]
  done: boolean
}) {
  return (
    <Ul className={`${styles.description} ${done && styles.done}`}>
      {description.map((item) => (
        <Li key={item}>
          {done && <Check />}
          {markdownify(item)}
        </Li>
      ))}
    </Ul>
  )
}
