import React from 'react'
import FeaturesList from '../../molecules/FeaturesList'
import styles from './Cta.module.css'
import ButtonActions from '../../molecules/ButtonActions'

const actions = [
  {
    title: 'Launch app',
    url: 'https://market.oceanprotocol.com'
  },
  {
    title: 'Start Building',
    url: '/developers'
  }
]

export default function Cta() {
  return (
    <div className={styles.cta}>
      <FeaturesList small />
      <ButtonActions actions={actions} className={styles.actions} />
    </div>
  )
}
