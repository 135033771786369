import React from 'react'
import MilestoneDescription from './MilestoneDescription'
import styles from './Milestone.module.css'

export interface MilestoneItem {
  title: string
  date: string
  description: string[]
  done?: boolean
}

export default function Milestone({ milestone }: { milestone: MilestoneItem }) {
  const { done, title, date, description } = milestone

  return (
    <div className={`${styles.milestone} ${done && styles.done}`}>
      <header className={styles.header}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.date}>
          {date} {!done && <span>*</span>}
        </p>
      </header>
      <MilestoneDescription description={description} done={done} />
    </div>
  )
}
