import React from 'react'
import shortid from 'shortid'
import { graphql, useStaticQuery } from 'gatsby'
import Section from '../../molecules/Section'
import Paper, { PaperItem } from './Paper'
import styles from './index.module.css'

const query = graphql`
  query SectionPapersQuery {
    content: allFile(filter: { relativePath: { eq: "sections/papers.json" } }) {
      edges {
        node {
          childSectionsJson {
            title
            papers {
              title
              description
              version
              file {
                name
                extension
                prettySize
              }
              translations {
                title
                file {
                  name
                  extension
                  prettySize
                }
              }
            }
          }
        }
      }
    }
  }
`

export default function SectionPapers() {
  const data = useStaticQuery(query)
  const content = data.content.edges[0].node.childSectionsJson

  return (
    <Section
      id="papers"
      title={content.title}
      headerCenter
      className={styles.section}
    >
      <div className={styles.papers}>
        {content.papers.map((paper: PaperItem) => (
          <Paper key={shortid.generate()} paper={paper} />
        ))}
      </div>
    </Section>
  )
}
