import React, { ReactNode } from 'react'
import styles from './Lists.module.css'
import shortid from 'shortid'
import { ReactComponent as Check } from '../../images/check.svg'

export const Ul = ({
  children,
  className
}: {
  children: ReactNode
  className?: string
}) => <ul className={`${styles.ul} ${className && className}`}>{children}</ul>

export const Ol = ({
  children,
  className
}: {
  children: ReactNode
  className?: string
}) => <ol className={`${styles.ol} ${className && className}`}>{children}</ol>

export const Li = ({ children }: { children: ReactNode }) => (
  <li className={styles.li}>{children}</li>
)

export interface LinkListItem {
  title: string
  url: string
}

export const LinkList = ({ links }: { links: LinkListItem[] }) => (
  <Ul>
    {links.map((link: LinkListItem) => (
      <Li key={shortid.generate()}>
        <a href={link.url}>{link.title}</a>
      </Li>
    ))}
  </Ul>
)

export const CheckList = ({ items }: { items: string[] }) => (
  <ul className={styles.checkList}>
    {items.map((item: string) => (
      <li key={shortid.generate()}>
        <Check />
        {item}
      </li>
    ))}
  </ul>
)
