import React from 'react'
import ReactGA from 'react-ga'
import Button from '../../atoms/Button'
import { markdownify } from '../../../utils'
import styles from './Paper.module.css'

interface PaperItemFile {
  name: string
  extension: string
  prettySize: string
}

export interface PaperItem {
  title: string
  description: string
  version: string
  file: PaperItemFile
  translations?: {
    title: string
    file: PaperItemFile
    key: string
  }[]
}

function trackDownload(key: string) {
  ReactGA.ga('send', 'event', 'download', key, 'button')
}

export default function Paper({ paper }: { paper: PaperItem }) {
  return (
    <article className={styles.paper}>
      <header>
        <h3>{paper.title}</h3>
        <div>{markdownify(paper.description)}</div>
      </header>

      <footer>
        <Button
          className={styles.button}
          style="primary"
          size="small"
          href={`/${paper.file.name}.pdf`}
          download
          onClick={() => trackDownload(paper.file.name)}
        >
          Download{' '}
          <span>
            {paper.file.extension} | {paper.file.prettySize}
          </span>
        </Button>

        {paper.translations && (
          <div className={styles.translations}>
            Translations:{' '}
            {paper.translations.map(
              (item: { title: string; file: PaperItemFile }) => (
                <a
                  className={styles.translation}
                  href={`/${paper.file.name}.pdf`}
                  download
                  onClick={() => trackDownload(paper.file.name)}
                  key={paper.file.name}
                >
                  {item.title}
                </a>
              )
            )}
          </div>
        )}

        <span className={styles.version}>{paper.version}</span>
      </footer>
    </article>
  )
}
