import React from 'react'
import { PageProps, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Roadmap from '../../components/pages/technology/Roadmap'
import Cta from '../../components/templates/PageTechnology/Cta'
import SectionPapers from '../../components/organisms/SectionPapers'

export default function PageGatbsyRoadmap(props: PageProps) {
  const content = (props.data as any).roadmap.edges[0].node.childTechnologyJson
  const { title, description, disclaimer } = content

  return (
    <Layout
      title={title}
      description={description}
      uri={props.uri}
      location={props.location}
      heroSmall
    >
      <Roadmap milestones={content.milestones} />
      <p>
        <small>* {disclaimer}</small>
      </p>
      <SectionPapers />
      <Cta />
    </Layout>
  )
}

export const contentQuery = graphql`
  query RoadmapPageQuery {
    roadmap: allFile(
      filter: { relativePath: { eq: "pages/technology/roadmap.json" } }
    ) {
      edges {
        node {
          childTechnologyJson {
            title
            description
            disclaimer
            milestones {
              title
              date
              description
              done
            }
          }
        }
      }
    }
  }
`
