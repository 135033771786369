import React from 'react'
import Milestone, { MilestoneItem } from './Milestone'
import Container from '../../../atoms/Container'
import styles from './index.module.css'

export default function Roadmap({
  milestones
}: {
  milestones: MilestoneItem[]
}) {
  return (
    <figure className={styles.roadmap}>
      <Container>
        {milestones.map((milestone) => (
          <Milestone key={milestone.title} milestone={milestone} />
        ))}
      </Container>
    </figure>
  )
}
